import styled from "styled-components";
import AuthContext, {UserSnapshot} from "../../../../Store/auth-context";
import {Await, defer, redirect, useLoaderData} from "react-router-dom";
import React, {Suspense, useContext, useEffect, useState} from "react";
import ICarouselDTO from "../../../../Models/DTOs/ICarouselDTO";
import GenericCarousel from "../../../../Components/Carousels/GenericCarousel";
import {GetCategoryFilmsCarousel, GetCategorySeries, GetNewAndCurrentAffairs} from "../../../../Api/Carousel";
import InfiniteScroll from "react-infinite-scroll-component";
import {AxiosError} from "axios";
import {RoutePaths} from "../../../../Constants/RoutePaths";
import {ContentType} from "../../../../Models/Enums/ContentType";
import PortraitList from "../../../../Components/UI/List/PortraitList";
import {ResponsiveBreakpoints} from "../../../../Constants/ResponsiveBreakpoints";
import ListContainer from "../../../../Components/UI/List/ListContainer";
import HeadingLoader from "../../../../Components/UI/PageLoaders/HeadingLoader";
import VideoSingleLoader from "../../../../Components/UI/PageLoaders/VideoSingleLoader";
import {PopUpType} from "../../../../Models/Enums/PopUpType";
import {BannerType} from "../../../../Models/Enums/BannerType";
import Banner from "../../../../Components/UI/Banner";
import {SetTitle} from "../../../../Helpers/Utility";

const Container = styled.section`
    padding: 1rem;
    width: 100%;
    max-width: calc(1616rem/16);
    margin: 0 auto;
    box-shadow: calc(1rem/16) 0 0 0 rgba(0,0,0,0.5), calc(-1rem/16) 0 0 0 rgba(0,0,0,0.5);
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
        padding: 1.5rem;
    }
`;

const DummCarouselWrapper = styled.div`
    margin: 3rem 0;
`;

function CategoryDetailsScreen(props: { popUpType?: PopUpType, bannerType?: BannerType }) {
    const authCtx = useContext(AuthContext);

    const { filmsPromise, seriesPromise, id, newPromise } = useLoaderData() as {
        filmsPromise: Promise<ICarouselDTO>;
        seriesPromise: Promise<ICarouselDTO[]>;
        featuredImage: string | undefined;
        newPromise?: Promise<ICarouselDTO[]>;
        id: number;
    };
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [scrollCarousels, setScrollCarousels] = useState<ICarouselDTO[]>([]);

    useEffect(() => {
        return function CleanUp() {
            //Resets scroller items on unmount
            setScrollCarousels([]);
            setHasMore(true);
        };
    }, [id]);

    useEffect(() => {
        SetTitle(props.bannerType === BannerType.WeeklyShows ? 'Weekly Shows' : 'David Icke');
    }, [props.bannerType]);

    async function GetContent(contentId: number, skip: number) {
        const take = 3;
        const result = await GetCategorySeries(
            contentId,
            authCtx.UserDetails.AspNetUserId,
            take,
            skip,
            5
        );

        if (result === null || result === undefined || result instanceof AxiosError || result.length <= 0) {
            setHasMore(false);
            return;
        }

        setScrollCarousels((prev) => [...prev, ...result]);
    }

    const loadMoreLoader = (paddingTop: number) => {
        return (
            <>
                <HeadingLoader paddingTop={ `${ paddingTop }` }  />

                <ListContainer>
                    { [...Array(5)].map((e, i) => (
                        <VideoSingleLoader paddingTop="8" key={ i } />
                    )) }
                </ListContainer>
            </>
        )
    };

    return (
        <React.Fragment key={id}>
            <Banner apiEnum={ props.bannerType } />

            <Container>
                <Suspense
                    fallback={
                        <>
                            { loadMoreLoader(0) }

                            <DummCarouselWrapper>
                                { loadMoreLoader(0) }
                            </DummCarouselWrapper>

                            <DummCarouselWrapper>
                                { loadMoreLoader(0) }
                            </DummCarouselWrapper>
                        </>
                    }
                >
                    { newPromise ? (
                        <Await resolve={newPromise}>
                            { (newsData) => {
                                const news = newsData as ICarouselDTO;

                                return <PortraitList carousel={news} height={225} mobileHeight={500} shrink={true} />;
                            }}
                        </Await>
                    ) : null }

                    <Await resolve={filmsPromise}>
                        {(filmData) => {
                            return (
                                <Await resolve={seriesPromise}>
                                    {(seriesData) => {
                                        const films = filmData as ICarouselDTO;
                                        const series = seriesData as ICarouselDTO[];
                                        let hasFilms = false;

                                        let carousels = [] as ICarouselDTO[];

                                        if (
                                            films !== null &&
                                            films !== undefined &&
                                            films.Content !== null &&
                                            films.Content !== undefined &&
                                            films.Content.length > 0
                                        ) {
                                            hasFilms = true;

                                            carousels = [films, ...series];
                                        } else {
                                            carousels = series;
                                        }

                                        let length = hasFilms
                                            ? [...carousels, ...scrollCarousels].length - 1
                                            : [...carousels, ...scrollCarousels].length;

                                        return (
                                            <InfiniteScroll
                                                dataLength={length}
                                                hasMore={hasMore}
                                                next={() => GetContent(id, length)}
                                                loader={
                                                    <DummCarouselWrapper>
                                                        { loadMoreLoader(0) }
                                                    </DummCarouselWrapper>
                                                }
                                            >
                                                {[...carousels, ...scrollCarousels].map((content, i) => {
                                                    let contentLink = undefined;

                                                    if (
                                                        content.ContentId !== undefined &&
                                                        content.ContentType !== undefined
                                                    ) {
                                                        switch (content.ContentType) {
                                                            case ContentType.Film:
                                                                //View all films
                                                                contentLink = RoutePaths.CategoryFilms(content.ContentId);
                                                                break;
                                                            case ContentType.Series:
                                                                contentLink = RoutePaths.SeriesEpisodes(content.ContentId);
                                                                break;
                                                            default:
                                                                break;
                                                        }
                                                    }

                                                    return (
                                                        <GenericCarousel
                                                            key={i}
                                                            carousel={content}
                                                            viewMoreText={"View All"}
                                                            viewMoreLocation={contentLink}
                                                            AmountToShow={5}
                                                        />
                                                    );
                                                })}
                                            </InfiniteScroll>
                                        );
                                    }}
                                </Await>
                            );
                        }}
                    </Await>
                </Suspense>
            </Container>
        </React.Fragment>
    );
}

export default CategoryDetailsScreen;

export async function Loader({ id, featuredImage }: { id: number, featuredImage: string | undefined }) {
    if (id === null || id === undefined || id <= 0) {
        //Redirect
        return redirect(RoutePaths.Browse);
    }

    const userDetails = await UserSnapshot;

    async function GetNews() {
        const result = await GetNewAndCurrentAffairs(userDetails.AspNetUserId);

        if (
            result === null ||
            result === undefined ||
            result instanceof AxiosError ||
            result.Content === null ||
            result.Content.length <= 0
        ) {
            return null;
        }

        const manualOrder: string[] = [
            "Messengers with Christianne van Wijk",
            "Health Code with Jaymie Icke",
            "Classified with Richard Willett",
            "Gareth Icke Tonight",
            "The Dot Connector with David Icke",
            "Secret Knowledge with Michael Feeley",
        ];

        // Sort Content by custom order (above titles)
        const sortedData = result.Content.sort((a, b) => {
            return manualOrder.indexOf(a.Title) - manualOrder.indexOf(b.Title);
        });

        return result;
    }

    return defer({
        id: id,
        filmsPromise: GetCategoryFilmsCarousel(
            id,
            userDetails.CurrentCountryCode,
            userDetails.AspNetUserId,
            5,
            0
        ),
        featuredImage: featuredImage,
        seriesPromise: GetCategorySeries(id, userDetails.AspNetUserId, 3, 0, 5),
        newPromise: id === 13 ? GetNews() : undefined,
    });
}
