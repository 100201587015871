import { Suspense, useContext, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Await, defer, useLoaderData } from "react-router-dom";
import styled from "styled-components";
import { SwiperSlide } from "swiper/react";
import {
    GetCategories,
    GetCustomCarousels,
    GetLatest,
    GetNewAndCurrentAffairs,
    GetTopRatedCarousel,
    GetEpisodeBySeason, GetEpisodesByYear,
} from "../../../Api/Carousel";
import Carousel from "../../../Components/Carousels/Carousel";
import CarouselItem from "../../../Components/Carousels/CarouselItem";
import CarouselItemPortrait from "../../../Components/Carousels/CarouselItemPortrait";
import DownloadAppAd from "../../../Components/UI/Ads/DownloadAppAd";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import Heading from "../../../Components/UI/Text/Heading";
import useWindowDimensions from "../../../Helpers/UseWindowDimensions";
import { notEmpty, SetTitle } from "../../../Helpers/Utility";
import ICarouselDTO from "../../../Models/DTOs/ICarouselDTO";
import AuthContext, { UserSnapshot } from "../../../Store/auth-context";
import { AxiosError } from "axios";
import PopUp from "../../../Components/UI/Modals/PopUp";
import { PopUpType } from "../../../Models/Enums/PopUpType";
import Banner from "../../../Components/UI/Banner";
import { BannerType } from "../../../Models/Enums/BannerType";
import ListContainer from "../../../Components/UI/List/ListContainer";
import VideoSingleLoader from "../../../Components/UI/PageLoaders/VideoSingleLoader";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import HeadingLoader from "../../../Components/UI/PageLoaders/HeadingLoader";
import UserOnboarding from "../../../Components/UI/UserOnboarding";

const Container = styled.section`
    padding: 1rem;
    width: 100%;
    max-width: calc(1616rem/16);
    margin: 0 auto;
    box-shadow: calc(1rem/16) 0 0 0 rgba(0,0,0,0.5), calc(-1rem/16) 0 0 0 rgba(0,0,0,0.5);
    .infinite-scroll-component {
        overflow: visible !important;
    }

    @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}em/16)) {
        padding: 1.5rem;
    }
`;

const BrowseContent = styled.div`

`;

const CarouselContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 32px;
`;

const CarouselObject = styled.div`
    display: flex;
    flex-direction: column;
    h3 {
        margin-top: 0;
        margin-bottom: 1rem;
    }
`;

const BrowseCarousel = styled.div`

`;

const PortraitImageContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: flex-start;
`;

const BottomImageContainer = styled.div`
    margin: 30px 0 0 0;
`;

function BrowseScreenFree() {
    const
        { newsPromise, topRatedPromise, latestPromise, ickeBytesPromise } = useLoaderData() as {
            newsPromise: Promise<ICarouselDTO>;
            topRatedPromise: Promise<ICarouselDTO | null>;
            latestPromise: Promise<ICarouselDTO | null>;
            ickeBytesPromise: Promise<ICarouselDTO | null>;
        },
        authCtx = useContext(AuthContext),
        { width } = useWindowDimensions(),
        [categoriesCarousel, setCategoriesCarousel] = useState<ICarouselDTO>(),
        [customCarousels, setCustomCarousels] = useState<ICarouselDTO[]>([]),
        [hasCategories, setHasCategories] = useState<boolean>(false),
        [hasCustom, setHasCustom] = useState<boolean>(false),
        hasMore = !hasCustom || !hasCategories,
        carouselCount = useRef<number>(0),
        CustomCarousels = async () => {
            const result = await GetCustomCarousels(
                authCtx.UserDetails.CurrentCountryCode,
                authCtx.UserDetails.AspNetUserId
            );

            if (result === null || result instanceof AxiosError || result.length <= 0) {
                setHasCustom(true);
                return;
            }

            setCustomCarousels(result);
            setHasCustom(true);
        },
        Categories = async () => {
            const result = await GetCategories(
                authCtx.UserDetails.AspNetUserId
            );

            if (
                result === null ||
                result === undefined ||
                result instanceof AxiosError ||
                result.Content === null ||
                result.Content.length <= 0
            ) {
                setHasCategories(true);
                return;
            }

            setCategoriesCarousel(result);
            setHasCategories(true);
        },
        GetCarousels = async () => {
            if (!hasCustom) {
                await CustomCarousels();
                return;
            }

            if (!hasCategories) {
                await Categories();
                return;
            }
        },
        loadMoreLoader = (paddingTop: number) => (
            <BrowseCarousel>
                <HeadingLoader paddingTop={`${paddingTop}`} />

                <ListContainer>
                    {[...Array(5)].map((e, i) => (
                        <VideoSingleLoader paddingTop="8" key={i} />
                    ))}
                </ListContainer>
            </BrowseCarousel>
        ),
        daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Sunday'];

    return (
        <>
            <PopUp
                canBeDismissed={true}
                apiEnum={PopUpType.Browse}
                isFullScreen={true}
            />

            <Banner apiEnum={BannerType.Browse} />

            <Container>
                <BrowseContent>
                    <InfiniteScroll
                        dataLength={carouselCount.current}
                        hasMore={hasMore}
                        next={GetCarousels}
                        loader={loadMoreLoader(32)}
                    >
                        <CarouselContainer>
                            <Suspense fallback={loadMoreLoader(0)}>
                                <Await resolve={newsPromise}>
                                    {(data) => {
                                        const news = data as ICarouselDTO;
                                        return news ? (
                                            <CarouselObject key={"c" + news?.Title}>
                                                <Heading type={HeadingType.H3}>Weekly Shows</Heading>

                                                <PortraitImageContainer>
                                                    {news.Content.map((content, index) => {
                                                        return (
                                                            <CarouselItemPortrait
                                                                key={index.toString() + content.Id}
                                                                Content={content}
                                                                releaseDayOfWeek={ daysOfWeek[index % daysOfWeek.length] }
                                                            />
                                                        );
                                                    })}
                                                </PortraitImageContainer>
                                            </CarouselObject>
                                        ) : null;
                                    }}
                                </Await>
                            </Suspense>

                            <Suspense fallback={loadMoreLoader(0)}>
                                <Await resolve={ickeBytesPromise}>
                                    {(ickeBytesData) => {
                                        const ickeBytes = ickeBytesData as ICarouselDTO;
                                        return (
                                            <Await resolve={topRatedPromise}>
                                                {(topRatedData) => {
                                                    const topRated = topRatedData as ICarouselDTO;
                                                    return (
                                                        <Await resolve={latestPromise}>
                                                            {(latestData) => {
                                                                const latest = latestData as ICarouselDTO;
                                                                const carousel = [
                                                                    topRated,
                                                                    latest,
                                                                    ickeBytes,
                                                                    ...customCarousels,
                                                                ];

                                                                const carouselFiltered = carousel !== undefined ? carousel.filter(notEmpty) : [];
                                                                carouselCount.current = carouselCount.current + carouselFiltered.length;

                                                                return (
                                                                    <>
                                                                        {carouselFiltered
                                                                            .sort((x) => x.Position)
                                                                            .map((item) => {
                                                                                return (
                                                                                    <CarouselObject
                                                                                        key={"c" + item.Title}
                                                                                    >
                                                                                        <BrowseCarousel>
                                                                                            <Heading type={HeadingType.H3}>
                                                                                                {item.Title}
                                                                                            </Heading>

                                                                                            <Carousel
                                                                                                arrows={true}
                                                                                                autoPlay={false}
                                                                                                bullets={false}
                                                                                                sliderPerView={
                                                                                                    width > 1115
                                                                                                        ? 5
                                                                                                        : 2
                                                                                                }
                                                                                                spaceBetween={16}
                                                                                                loop={false}
                                                                                            >
                                                                                                {item.Content.map(
                                                                                                    (content, index) => {
                                                                                                        return (
                                                                                                            <SwiperSlide
                                                                                                                key={index.toString() + content.Id}
                                                                                                            >
                                                                                                                <CarouselItem
                                                                                                                    Content={content}
                                                                                                                    disableOverlay={true}
                                                                                                                />
                                                                                                            </SwiperSlide>
                                                                                                        );
                                                                                                    }
                                                                                                )}
                                                                                            </Carousel>
                                                                                        </BrowseCarousel>
                                                                                    </CarouselObject>
                                                                                );
                                                                            })
                                                                        }
                                                                    </>
                                                                );
                                                            }}
                                                        </Await>
                                                    );
                                                }}
                                            </Await>
                                        );
                                    }}
                                </Await>
                            </Suspense>

                            {categoriesCarousel !== null && categoriesCarousel !== undefined ? (
                                <CarouselObject key={"ca" + categoriesCarousel?.Title}>
                                    <Heading type={HeadingType.H3}>
                                        {categoriesCarousel.Title}
                                    </Heading>

                                    <BrowseCarousel>
                                        <PortraitImageContainer>
                                            {categoriesCarousel.Content.map((content, index) => {
                                                return (
                                                    <CarouselItemPortrait
                                                        Content={content}
                                                        key={index.toString() + content.Id}
                                                        showOverlay={true}
                                                    />
                                                );
                                            })}
                                        </PortraitImageContainer>
                                    </BrowseCarousel>
                                </CarouselObject>
                            ) : null}
                        </CarouselContainer>
                    </InfiniteScroll>

                    <BottomImageContainer>
                        <DownloadAppAd />
                    </BottomImageContainer>
                </BrowseContent>
            </Container>
        </>
    );
}

export default BrowseScreenFree;

export async function Loader() {
    const
        controller = new AbortController(),
        userDetails = await UserSnapshot,
        GetNews = async () => {
            const result = await GetNewAndCurrentAffairs(
                userDetails.AspNetUserId
            );

            if (
                result === null ||
                result === undefined ||
                result instanceof AxiosError ||
                result.Content === null ||
                result.Content.length <= 0
            ) {
                return null;
            }

            const manualOrder: string[] = [
                "Playbook of War",
                "Health Code with Jaymie Icke",
                "Classified with Richard Willett",
                "Gareth Icke Tonight",
                "The Dot Connector with David Icke",
                "WTAF With Rich & Gaz",
            ];

            // Sort Content by custom order (above titles)
            const sortedData = result.Content.sort((a, b) => {
                return manualOrder.indexOf(a.Title) - manualOrder.indexOf(b.Title);
            });

            //console.log(sortedData);

            return result;
        },

        TopRated = async () => {
            const result = await GetTopRatedCarousel(
                userDetails.CurrentCountryCode,
                userDetails.AspNetUserId
            );

            if (
                result === null ||
                result === undefined ||
                result instanceof AxiosError ||
                result.Content === null ||
                result.Content.length <= 0
            ) {
                return null;
            }

            return result;
        },
        Latest = async () => {
            const result = await GetLatest(
                userDetails.CurrentCountryCode,
                userDetails.AspNetUserId
            );

            if (
                result === null ||
                result === undefined ||
                result instanceof AxiosError ||
                result.Content === null ||
                result.Content.length <= 0
            ) {
                return null;
            }

            // console.log(result);

            return result;
        },
        IckeBytes = async () => {
            const result = await GetEpisodesByYear(
                166,
                userDetails.CurrentCountryCode,
                "",
                30,
                0,
                0,
                controller,
                'd'
            );

            //console.log(result);

            if (
                result === null ||
                result === undefined ||
                result instanceof AxiosError ||
                result.Content === null ||
                result.Content.length <= 0
            ) {
                return null;
            }

            result.Content = result.Content.filter(item => item.IsClickeable);
            result.Title = "IckeBites";
            return result;
        };

    SetTitle("Browse");

    return defer({
        newsPromise: GetNews(),
        topRatedPromise: TopRated(),
        latestPromise: Latest(),
        ickeBytesPromise: IckeBytes(),
    });
}
